var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mapWrp",staticClass:"map-wrp",on:{"contextmenu":function (evt) { return evt.preventDefault(); }}},[_c('LoadingOverlay',{attrs:{"loadProgress":_vm.allocationLoading,"background":{
      color: '#000',
      alpha: 0.7
    }}}),_c('LoadingOverlay',{attrs:{"loadProgress":_vm.filterLoading,"background":{
      color: '#000',
      alpha: 0.7
    }}}),_c('LoadingOverlay',{attrs:{"loadProgress":_vm.bindedImageUpdateLoading,"background":{
      color: '#000',
      alpha: 0.7
    }}}),_c('div',{staticClass:"map"},[_c('transition',{attrs:{"name":"fade-scale"}},[_c('Popup',{directives:[{name:"show",rawName:"v-show",value:(_vm.popupIsVisible),expression:"popupIsVisible"}],ref:"plotDataPopup",attrs:{"data":_vm.popupData,"isLoading":_vm.popupIsLoading}})],1),_c('canvas',{ref:"map",attrs:{"id":"map"}}),_vm._m(0),_c('ul',{staticClass:"tools"},[_c('li',{staticClass:"tools__item zoom"},[_c('button',{staticClass:"zoom-btn",on:{"click":function($event){$event.preventDefault();return _vm.increaseZoom()}}},[_vm._v(" + ")]),_c('button',{staticClass:"zoom-btn",on:{"click":function($event){$event.preventDefault();return _vm.decreaseZoom()}}},[_vm._v(" - ")])])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"update-btn"},[_c('div',{staticClass:"update-btn__progress-bar"})])}]

export { render, staticRenderFns }