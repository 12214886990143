import Constants from '@/variables/map/constants';
import assets from '@/variables/map/assets';

const constructions = [
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x: Constants.MAP_INIT_POSITION_X + Constants.ROAD_SIZE,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.ROAD_SIZE
      + Constants.REGION_SIZE + Constants.LINE_WIDTH,
    background: 0x00D1FF,
    borderRadius: 100,
    content: {
      text: 'Airport \nT3',
      textColor: 0xffffff,
      icon: assets.plane,
      iconWidth: 600,
      iconHeight: 700,
      iconMarginTop: 50,
    },
    id: 1,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x: Constants.MAP_INIT_POSITION_X + Constants.MAP_SIZE + Constants.LINE_WIDTH,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE + Constants.ROAD_SIZE,
    background: 0x00D1FF,
    borderRadius: 100,
    content: {
      text: 'Airport \nT2',
      textColor: 0xffffff,
      icon: assets.plane,
      iconWidth: 600,
      iconHeight: 700,
      iconMarginTop: 50,
    },
    id: 2,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
      + Constants.LINE_WIDTH + Constants.ROAD_SIZE,
    y: Constants.MAP_INIT_POSITION_Y + Constants.ROAD_SIZE,
    background: 0x00D1FF,
    borderRadius: 100,
    content: {
      text: 'Airport \nT4',
      textColor: 0xffffff,
      icon: assets.plane,
      iconWidth: 600,
      iconHeight: 700,
      iconMarginTop: 50,
    },
    id: 3,
  },
  {
    width: Constants.BLOCK_SIZE * 3 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 3 + Constants.ROAD_SIZE * 3,
    y: Constants.MAP_INIT_POSITION_Y + Constants.MAP_SIZE + Constants.LINE_WIDTH,
    background: 0x00D1FF,
    borderRadius: 100,
    content: {
      text: 'Airport T1',
      textColor: 0xffffff,
      icon: assets.plane,
      iconWidth: 600,
      iconHeight: 700,
      iconMarginTop: 50,
    },
    id: 4,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x: Constants.MAP_INIT_POSITION_X + Constants.ROAD_SIZE * 3 + Constants.BLOCK_SIZE * 3,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.ROAD_SIZE
      + Constants.REGION_SIZE + Constants.LINE_WIDTH,
    background: 0xFCE514,
    borderRadius: 100,
    content: {
      text: 'Battle \nArena',
      textColor: 0x2B2D35,
      icon: assets.cup,
      iconWidth: 500,
      iconHeight: 500,
      iconMarginTop: 150,
    },
    id: 5,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x: Constants.MAP_INIT_POSITION_X + Constants.ROAD_SIZE * 5 + Constants.BLOCK_SIZE * 7,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.ROAD_SIZE
      + Constants.REGION_SIZE + Constants.LINE_WIDTH,
    background: 0x2B2D35,
    borderRadius: 100,
    content: {
      text: 'Racing \nArea',
      textColor: 0xffffff,
      icon: assets.flag,
      iconWidth: 500,
      iconHeight: 500,
      iconMarginTop: 150,
    },
    id: 6,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 11 + Constants.ROAD_SIZE * 6,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.ROAD_SIZE
      + Constants.REGION_SIZE + Constants.LINE_WIDTH,
    background: 0x30A7FE,
    borderRadius: 100,
    content: {
      text: 'Fishing \nLake',
      textColor: 0xffffff,
      icon: assets.rod,
      iconWidth: 500,
      iconHeight: 500,
      iconMarginTop: 150,
    },
    id: 7,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x: Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE - Constants.BLOCK_SIZE * 2,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.MAP_SIZE + Constants.LINE_WIDTH,
    background: 0x00D1FF,
    borderRadius: 100,
    content: {
      text: 'Port',
      textColor: 0xffffff,
      icon: assets.anchor,
      iconWidth: 500,
      iconHeight: 500,
      iconMarginTop: 150,
    },
    id: 8,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 4 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
      + Constants.LINE_WIDTH + Constants.ROAD_SIZE,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.ROAD_SIZE * 3
      + Constants.BLOCK_SIZE * 3,
    background: {
      background: assets.adventureParkBackground,
      type: 'image',
    },
    borderRadius: 100,
    content: {
      text: 'Adventure \nPark',
      textColor: 0xffffff,
      icon: assets.roller,
      iconWidth: 500,
      iconHeight: 500,
      iconMarginTop: 900,
    },
    id: 9,
  },
  // {
  //   width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
  //   height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
  //   x:
  //     Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
  //     + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2 + Constants.LINE_WIDTH * 2,
  //   y:
  //     Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
  //     - Constants.BLOCK_SIZE * 2 + Constants.LINE_WIDTH + Constants.LINE_WIDTH / 2,
  //   background: 0xFCE514,
  //   borderRadius: 0,
  //   content: {
  //     text: 'Main \nStadium',
  //     textColor: 0x2B2D35,
  //     icon: assets.cup,
  //     iconWidth: 500,
  //     iconHeight: 500,
  //     iconMarginTop: 150,
  //   },
  //   id: 10,
  // },
  // {
  //   width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
  //   height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
  //   x:
  //     Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
  //     + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2 + Constants.LINE_WIDTH * 2,
  //   y:
  //     Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
  //     + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
  //     + Constants.LINE_WIDTH + Constants.LINE_WIDTH / 2,
  //   background: 0xFCE514,
  //   borderRadius: 0,
  //   content: {
  //     text: 'Public \nGallery',
  //     textColor: 0x2B2D35,
  //     icon: assets.gallery,
  //     iconWidth: 500,
  //     iconHeight: 500,
  //     iconMarginTop: 150,
  //   },
  //   id: 11,
  // },
  {
    width: Constants.BLOCK_SIZE * 3 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH,
    x: Constants.MAP_INIT_POSITION_X - Constants.BLOCK_SIZE * 3 + 12,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.MAP_SIZE
      - Constants.BLOCK_SIZE * 2 - Constants.ROAD_SIZE,
    background: 0x00D1FF,
    borderRadius: 100,
    content: {
      text: 'Marina',
      textColor: 0xffffff,
      icon: assets.yatch,
      iconWidth: 800,
      iconHeight: 700,
      iconMarginTop: 50,
    },
    id: 12,
  },
  // {
  //   width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
  //   height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
  //   x:
  //     Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
  //     - (Constants.BLOCK_SIZE * 2 - Constants.LINE_WIDTH * 2),
  //   y:
  //     Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
  //     + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
  //     + Constants.LINE_WIDTH + Constants.LINE_WIDTH / 2,
  //   background: 0xFCE514,
  //   borderRadius: 0,
  //   content: {
  //     text: 'Auto \nValley',
  //     textColor: 0x2B2D35,
  //     icon: assets.autovalley,
  //     iconWidth: 700,
  //     iconHeight: 1000,
  //     iconMarginTop: 40,
  //     iconMarginBottom: -300,
  //   },
  //   id: 13,
  // },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
    x: Constants.MAP_INIT_POSITION_X - (Constants.BLOCK_SIZE * 2 - Constants.LINE_WIDTH * 3),
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 4 + Constants.ROAD_SIZE * 3
      + Constants.LINE_WIDTH + Constants.LINE_WIDTH / 2,
    background: 0xe1e1e1,
    borderRadius: 100,
    content: {
      text: 'Pirate \nCove',
      textColor: 0x000000,
      icon: assets.ship,
      iconWidth: 600,
      iconHeight: 800,
      iconMarginTop: 40,
      iconMarginBottom: -300,
    },
    id: 14,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH * 3,
    x: Constants.MAP_INIT_POSITION_X - (Constants.BLOCK_SIZE * 2 - Constants.LINE_WIDTH * 3),
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
      - Constants.BLOCK_SIZE * 4 - 90,
    background: 0x2B2D35,
    borderRadius: 100,
    content: {
      text: 'Spaceport',
      textColor: 0xffffff,
      icon: assets.rocket,
      iconWidth: 800,
      iconHeight: 800,
      iconMarginTop: 80,
      iconMarginBottom: -300,
    },
    id: 14,
  },
];

export default constructions;
