import Constants from '@/variables/map/constants';
import assets from './assets';

const logos = [
  // {
  //   x: 5560,
  //   y: -3320,
  //   width: 512,
  //   height: 512,
  //   useOffset: true,
  //   img: assets.starship,
  // },
  // {
  //   x: 5560,
  //   y: -3320,
  //   width: 512,
  //   height: 512,
  //   useOffset: true,
  //   img: assets.ggToorWhite,
  // },
  {
    x: 4290,
    y: 8005,
    width: 203,
    height: 100,
    useOffset: true,
    img: assets.defi,
  },
  {
    x: 4520,
    y: 8008,
    width: 128,
    height: 128,
    useOffset: true,
    img: assets.aussie,
  },
  {
    x: 8008,
    y: 7432,
    width: 128,
    height: 128,
    useOffset: true,
    img: assets.wic,
  },
  {
    x: -3320,
    y: -4680,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.wsb2,
  },
  {
    x: -5320,
    y: 4200,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.wsb1,
  },
  {
    x: 7560,
    y: 6200,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.projectNightfall,
  },
  {
    x: -3320,
    y: 5560,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.nftb,
  },
  {
    x: -4328,
    y: -2968,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.elongate,
  },
  {
    x: 7704,
    y: -4500,
    width: 256,
    height: 180,
    useOffset: true,
    img: assets.altcoin,
  },
  {
    x: 8008,
    y: 7240,
    width: 128,
    height: 128,
    useOffset: true,
    img: assets.cryptoMom,
  },
  {
    x: 7816,
    y: 6856,
    width: 128,
    height: 128,
    useOffset: true,
    img: assets.sonyaMulkeet,
  },
  {
    x: -3896,
    y: -6616,
    width: 1024,
    height: 1024,
    useOffset: true,
    img: assets.untitled,
  },
  {
    x: 5288,
    y: -3233,
    width: 128,
    height: 80,
    useOffset: true,
    img: assets.fmfh,
  },
  // {
  //   x: -6568,
  //   y: -4536,
  //   width: 256,
  //   height: 256,
  //   useOffset: true,
  //   img: assets.collectaball,
  // },
  {
    x: 5624,
    y: 2904,
    width: 1024,
    height: 1024,
    useOffset: true,
    img: assets.sydtek,
  },
  {
    x: -4328,
    y: -4056,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.cyberHornets,
  },
  {
    x: -5784,
    y: -6552,
    width: 128,
    height: 128,
    useOffset: true,
    img: assets.shesBlockchainSavvy,
  },
  {
    x: -5784,
    y: -6744,
    width: 128,
    height: 128,
    useOffset: true,
    img: assets.blockchainCollective,
  },
  {
    x: -5784,
    y: -6744,
    width: 128,
    height: 128,
    useOffset: true,
    img: assets.blockchainCollective,
  },
  {
    x: 6200,
    y: -3320,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 5560,
    y: -3960,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 4840,
    y: -4680,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 4200,
    y: -4680,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 4200,
    y: -5320,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 4840,
    y: -5320,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 6984,
    y: -3896,
    width: 1024,
    height: 1024,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 6984,
    y: -6616,
    width: 1024,
    height: 1024,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 7368,
    y: -4872,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 5848,
    y: -6072,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 6168,
    y: -6072,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 6168,
    y: -6392,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 5848,
    y: -6392,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 4664,
    y: -6216,
    width: 224,
    height: 224,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x: 4664,
    y: -3496,
    width: 224,
    height: 224,
    useOffset: true,
    img: assets.ggToorWhite,
  },
  {
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
      + Constants.REGION_SIZE / 2 - Constants.COMMERCIAL_PLOT_SIZE / 2 + 16,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
      + Constants.REGION_SIZE / 2 - Constants.COMMERCIAL_PLOT_SIZE / 2 + 16,
    width: Constants.COMMERCIAL_PLOT_SIZE - 28,
    height: Constants.COMMERCIAL_PLOT_SIZE - 28,
    useOffset: false,
    img: assets.ggToor,
  },
  {
    x: -4680,
    y: -6680,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.monsterJam,
  },
  {
    x: -5416,
    y: 7912,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -5032,
    y: 7912,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -4712,
    y: 7912,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -4328,
    y: 7912,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -5416,
    y: 7528,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -5032,
    y: 7528,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -4712,
    y: 7528,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -4328,
    y: 7528,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -5416,
    y: 7208,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -5032,
    y: 7208,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -4712,
    y: 7208,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -4328,
    y: 7208,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -5416,
    y: 6824,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -5032,
    y: 6824,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -4712,
    y: 6824,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -4328,
    y: 6824,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.epik,
  },
  {
    x: -5320,
    y: -6040,
    width: 512,
    height: 512,
    useOffset: true,
    img: assets.onetreeplanted,
  },
  {
    x: -5592,
    y: -6360,
    width: 128,
    height: 128,
    useOffset: true,
    img: assets.readyplayerme,
  },
  {
    x: 6344,
    y: 7032,
    width: 256,
    height: 256,
    useOffset: true,
    img: assets.drcTransformed,
  },
];

export default logos;
