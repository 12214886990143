import * as PIXI from 'pixi.js';
import {
  PlotInit,
  SpriteWithPlotData,
  MapIndexes,
  Position,
  Assets,
  MapTexture,
  Logo,
  Construction,
  Area,
  Region, ConstructionBackground,
} from '@/variables/map/types';
import Constants from '@/variables/map/constants';

class Drawing {
  protected readonly mapTexture: MapTexture | unknown = null

  constructor(mapTexture: MapTexture) {
    this.mapTexture = mapTexture;
  }

  getOffsetPosition(x: number | string, y: number | string): Position {
    x = Number(x);
    y = Number(y);

    const offsetX = x + Constants.OFFSET_X;
    const offsetY = Math.abs(y - Constants.OFFSET_Y);

    return {
      x: offsetX,
      y: offsetY,
    };
  }

  getPlotTexture(this: Drawing, app: PIXI.Application): PIXI.Texture {
    const plotGraphic = new PIXI.Graphics();
    plotGraphic.beginFill(0xffffff);
    plotGraphic.drawRect(0, 0, 16, 16);
    plotGraphic.endFill();
    return app.renderer.generateTexture(plotGraphic);
  }

  getSelectedPlotTexture(this: Drawing, app: PIXI.Application): PIXI.Texture {
    const graphic = new PIXI.Graphics();
    graphic.lineStyle(4, 0x32E322);
    graphic.beginFill(0x9DEE96);
    graphic.drawRect(0, 0, 16, 16);
    graphic.endFill();
    return app.renderer.generateTexture(graphic);
  }

  getSelectedPlotSprite(
    this: Drawing,
    app: PIXI.Application,
    plot: PlotInit,
  ): SpriteWithPlotData {
    const sprite = PIXI.Sprite.from(this.getSelectedPlotTexture(app));

    const offset = this.getOffsetPosition(plot.x, plot.y);
    const x = offset.x + Constants.MAP_INIT_POSITION_X;
    const y = offset.y - Number(plot.s) + Constants.MAP_INIT_POSITION_Y;

    sprite.position.set(x, y);
    sprite.width = Number(plot.s);
    sprite.height = Number(plot.s);
    sprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
    (sprite as SpriteWithPlotData).plotData = plot;

    return sprite;
  }

  getPlotSprite(this: Drawing, plot: PlotInit, sprite: PIXI.Sprite): SpriteWithPlotData {
    const size = Number(plot.s);

    const offset = this.getOffsetPosition(plot.x, plot.y);
    const x = offset.x + Constants.MAP_INIT_POSITION_X;
    const y = offset.y - Number(plot.s) + Constants.MAP_INIT_POSITION_Y;

    sprite.position.set(x, y);
    sprite.width = size;
    sprite.height = size;
    sprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;

    if (/#/.test(plot.c)) {
      plot.c = plot.c.substring(2);
    }

    if (plot.owned) {
      plot.c = '32FF7E';
    }

    sprite.tint = Number(`0x${plot.c}`);

    (sprite as SpriteWithPlotData).plotData = plot;

    return sprite;
  }

  drawRoadLine(
    mapIndexes: MapIndexes,
    position: Position,
    mapTextureGraphic: PIXI.Graphics,
    axis: 'x' | 'y',
  ): void {
    const {
      mapXIdx,
      mapYIdx,
      regionXIdx,
      regionYIdx,
    } = mapIndexes;
    const { x, y } = position;

    const baseArray = (this.mapTexture as MapTexture)[mapYIdx][mapXIdx];
    const hasNeighborByX = baseArray?.[regionYIdx]?.[regionXIdx + 1] === 1;
    const hasNeighborByY = baseArray?.[regionYIdx + 1]?.[regionXIdx] === 1;

    const lineToByX = {
      x: x + Constants.ROAD_SIZE,
      y,
    };
    const lineToByY = {
      x,
      y: y + Constants.ROAD_SIZE,
    };

    switch (axis) {
      case 'x':
        this.drawRoadLineByAxis(mapTextureGraphic, hasNeighborByX, position, lineToByX);
        break;
      case 'y':
        this.drawRoadLineByAxis(mapTextureGraphic, hasNeighborByY, position, lineToByY);
        break;
      default:
        break;
    }
  }

  drawRoadLineByAxis(
    mapTextureGraphic: PIXI.Graphics,
    hasNeighbor: boolean,
    position: Position,
    lineTo: Position,
  ): void {
    const { x, y } = position;

    if (hasNeighbor) {
      mapTextureGraphic.lineStyle(Constants.LINE_WIDTH, 0xffffff);
      mapTextureGraphic.moveTo(x, y);
      mapTextureGraphic.lineTo(
        lineTo.x,
        lineTo.y,
      );
      mapTextureGraphic.closePath();
    }
  }

  defineRoadLineDirection(
    mapTextureGraphic: PIXI.Graphics,
    mapIndexes: MapIndexes,
    position: Position,
  ): void {
    const { regionXIdx, regionYIdx } = mapIndexes;
    const { x, y } = position;

    if (regionXIdx % 2 === 0) {
      this.drawRoadLine(
        mapIndexes,
        { x: x + Constants.BLOCK_SIZE, y },
        mapTextureGraphic,
        'x',
      );
      this.drawRoadLine(
        mapIndexes,
        { x: x + Constants.BLOCK_SIZE, y: y + Constants.BLOCK_SIZE },
        mapTextureGraphic,
        'x',
      );
    }

    if (regionYIdx % 2 === 0) {
      this.drawRoadLine(
        mapIndexes,
        { x, y: y + Constants.BLOCK_SIZE },
        mapTextureGraphic,
        'y',
      );
      this.drawRoadLine(
        mapIndexes,
        { x: x + Constants.BLOCK_SIZE, y: y + Constants.BLOCK_SIZE },
        mapTextureGraphic,
        'y',
      );
    }
  }

  drawBlockOutline(mapTextureGraphic: PIXI.Graphics, position: Position): void {
    const { x, y } = position;

    mapTextureGraphic.lineStyle(Constants.LINE_WIDTH, 0xffffff);
    mapTextureGraphic.beginFill(0x000000, Constants.BACKGROUND_ALPHA);
    mapTextureGraphic.drawRect(x, y, Constants.BLOCK_SIZE, Constants.BLOCK_SIZE);
    mapTextureGraphic.endFill();
  }

  getCommercialPlotOutline(pathToImg: string, position: Position): PIXI.Container {
    const { x, y } = position;

    const container = new PIXI.Container();

    const sprite = PIXI.Sprite.from(pathToImg, {
      mipmap: PIXI.MIPMAP_MODES.ON,
    });
    sprite.width = Constants.COMMERCIAL_PLOT_SIZE;
    sprite.height = Constants.COMMERCIAL_PLOT_SIZE;
    sprite.position.set(x - Constants.MAP_MARGIN * 2, y - Constants.MAP_MARGIN * 2);

    container.addChild(this.getCommercialPlotBackground(position));
    container.addChild(sprite);

    return container;
  }

  getCommercialPlotBackground(position: Position): PIXI.Graphics {
    const { x, y } = position;

    const background = new PIXI.Graphics();
    background.beginFill(0x000000, Constants.BACKGROUND_ALPHA);
    background.drawRect(
      x + 8,
      y + 8,
      Constants.COMMERCIAL_PLOT_SIZE - 32,
      Constants.COMMERCIAL_PLOT_SIZE - 32,
    );
    background.endFill();

    return background;
  }

  defineOutlineType(
    commercialPlotsOutlineContainer: PIXI.Container,
    mapTextureGraphic: PIXI.Graphics,
    mapIndexes: MapIndexes,
    position: Position,
    assets: Assets,
  ): void {
    const {
      mapXIdx,
      mapYIdx,
      regionXIdx,
      regionYIdx,
    } = mapIndexes;

    const textureNumber = (this.mapTexture as MapTexture)[mapYIdx][mapXIdx][regionYIdx][regionXIdx];

    switch (textureNumber) {
      case 1:
        this.drawBlockOutline(mapTextureGraphic, position);
        break;
      case 2:
        commercialPlotsOutlineContainer.addChild(
          this.getCommercialPlotOutline(assets.green, position),
        );
        break;
      case 3:
        commercialPlotsOutlineContainer.addChild(
          this.getCommercialPlotOutline(assets.secondBlue, position),
        );
        break;
      case 4:
        commercialPlotsOutlineContainer.addChild(
          this.getCommercialPlotOutline(assets.firstBlue, position),
        );
        break;
      case 5:
        commercialPlotsOutlineContainer.addChild(
          this.getCommercialPlotOutline(assets.pink, position),
        );
        break;
      case 6:
        commercialPlotsOutlineContainer.addChild(
          this.getCommercialPlotOutline(assets.cyanBuild, position),
        );
        break;
      case 7:
        commercialPlotsOutlineContainer.addChild(
          this.getCommercialPlotOutline(assets.secondGreen, position),
        );
        break;
      default:
        break;
    }
  }

  getLogoSprite(logo: Logo): PIXI.Sprite {
    let x = logo.x;
    let y = logo.y;

    if (logo.useOffset) {
      const offset = this.getOffsetPosition(logo.x, logo.y);
      x = offset.x + Constants.MAP_INIT_POSITION_X;
      y = offset.y - logo.height + Constants.MAP_INIT_POSITION_Y;
    }

    const sprite = PIXI.Sprite.from(logo.img);
    sprite.width = logo.width;
    sprite.height = logo.height;
    sprite.position.set(x, y);

    return sprite;
  }

  getConstructionBackground(construction: Construction): PIXI.Graphics | PIXI.Sprite {
    const imageBackground = construction.background as ConstructionBackground;

    if (imageBackground?.type === 'image') {
      const background = PIXI.Sprite.from(imageBackground.background);
      background.width = construction.width;
      background.height = construction.height;
      background.x = 0;
      background.y = 0;
      background.alpha = Constants.BACKGROUND_ALPHA;
      return background;
    }

    const background = new PIXI.Graphics();
    background.beginFill(construction.background as number);
    background.drawRoundedRect(
      0,
      0,
      construction.width,
      construction.height,
      construction.borderRadius,
    );
    background.endFill();

    return background;
  }

  getConstructionIcon(construction: Construction): PIXI.Sprite {
    const icon = PIXI.Sprite.from(construction.content.icon);
    icon.height = construction.content.iconWidth;
    icon.width = construction.content.iconHeight;
    const iconX = (construction.width - icon.width) / 2;
    const iconY = construction.content.iconMarginTop;
    icon.position.set(iconX, iconY);

    return icon;
  }

  getConstructionText(construction: Construction, icon: PIXI.Sprite): PIXI.Text {
    const text = new PIXI.Text(
      construction.content.text,
      {
        fontFamily: 'DM Sans',
        fontSize: 200,
        fill: construction.content.textColor,
        fontWeight: '700',
        align: 'center',
      },
    );
    const textX = (construction.width - text.width) / 2;
    const textY = (construction.height - text.height + icon.height) / 2;
    text.position.set(textX, textY);

    return text;
  }

  getContructionContainer(construction: Construction): PIXI.Container {
    const container = new PIXI.Container();
    container.position.set(construction.x, construction.y);

    const icon = this.getConstructionIcon(construction);

    container.addChild(this.getConstructionBackground(construction));
    container.addChild(this.getConstructionText(construction, icon));
    container.addChild(icon);

    return container;
  }

  getCenterText(centerPosition: number, xIdx: number, yIdx: number): PIXI.Text {
    const text = new PIXI.Text(
      'City',
      {
        fontFamily: 'DM Sans',
        fontWeight: '700',
        fontSize: 500,
        fill: ['#fff'],
      },
    );

    text.position.set(
      centerPosition + Constants.MAP_INIT_POSITION_X
        + (Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE) * xIdx
        + (Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH) / 2
        - text.width / 2,
      centerPosition + Constants.MAP_INIT_POSITION_Y
        + (Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE) * yIdx
        + (Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE - Constants.LINE_WIDTH) / 2
        - text.height / 2,
    );

    return text;
  }

  getAreaBackground(area: Area): PIXI.Sprite {
    const background = PIXI.Sprite.from(area.img);
    background.alpha = Constants.BACKGROUND_ALPHA;
    background.width = area.width;
    background.height = area.height;

    return background;
  }

  getAreaText(area: Area): PIXI.Text {
    const text = new PIXI.Text(
      area.content.text,
      {
        fontFamily: 'DM Sans',
        fontSize: area.content.fontSize,
        fontWeight: '700',
        align: 'center',
        fill: area.content.color || 0xffffff,
      },
    );
    text.position.set(area.content.textPosition.x, area.content.textPosition.y);

    return text;
  }

  getAreaIcon(area: Area): PIXI.Sprite {
    const icon = PIXI.Sprite.from(area.content.icon);
    icon.width = area.content.iconWidth;
    icon.height = area.content.iconHeight;
    icon.position.set(area.content.iconPosition.x, area.content.iconPosition.y);

    return icon;
  }

  getAreaContainer(area: Area): PIXI.Container {
    const container = new PIXI.Container();
    container.position.set(area.x, area.y);

    container.addChild(this.getAreaBackground(area));
    container.addChild(this.getAreaText(area));
    container.addChild(this.getAreaIcon(area));

    return container;
  }

  getRegionSprite(region: Region): PIXI.Sprite {
    const sprite = PIXI.Sprite.from(region.img);
    sprite.x = region.x;
    sprite.y = region.y;
    sprite.width = Constants.COMMERCIAL_PLOT_SIZE;
    sprite.height = Constants.COMMERCIAL_PLOT_SIZE;

    return sprite;
  }
}

export default Drawing;
