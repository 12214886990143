var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'menu': true, visible: _vm.isVisible }},[_c('nav',{staticClass:"menu__nav"},_vm._l((_vm.nav),function(link){
var _obj;
return _c(link.disabled ? 'span' : 'router-link',{key:link.id,tag:"router-link",class:{
        'menu__nav-link': true,
        active: _vm.activeTab === link.tab,
      },attrs:{"to":{
        name: 'Map',
        query:
          {
            nav: link.query,
            x: _vm.$route.query.x,
            y: _vm.$route.query.y,
            scale: _vm.$route.query.scale
          }
      }}},[_c('span',{class:( _obj = {
          'menu__nav-link-icon-wrp': true
        }, _obj[link.query] = true, _obj.disabled = link.disabled, _obj )},[_c('img',{staticClass:"menu__nav-link-icon",attrs:{"src":'https://d1ak9kdvdopy8s.cloudfront.net/items/' + link.img}})]),_c('span',{staticClass:"menu__nav-link-text"},[_vm._v(_vm._s(link.label))])])}),1),_c('div',{class:{ 'menu__content-wrp': true, visible: _vm.isVisible }},[_c('button',{staticClass:"menu-toggle-btn",on:{"click":function($event){$event.preventDefault();return _vm.toggleMenuVisibility()}}},[_c('img',{class:{ 'menu-toggle-btn-icon': true, active: _vm.isVisible },attrs:{"src":require("@/assets/icons/map/short-arrow.png"),"alt":""}})]),_c('CustomScroll',[_c('transition',{attrs:{"name":"fade-to-left"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"menu__content"},[_c('transition',{attrs:{"name":"fade-to-left","mode":"out-in"}},[_c('keep-alive',[_c(_vm.activeTab,{ref:"menuComponent",tag:"component",attrs:{"selectedPLots":_vm.selectedPlots},on:{"update-binded-images":function($event){return _vm.updateBindedImages()},"start-image-binding":function (img) { return _vm.startImageBinding(img); },"cancel-image-binding":function($event){return _vm.cancelImageBinding()},"start-allocation":function (list) { return _vm.startAllocation(list); },"apply":function (filters) { return _vm.applyFilters(filters); },"buy":function (plot) { return _vm.buyPlot(plot); }}})],1)],1)],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }