var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',{staticClass:"collection-item-wrp"},[_c('router-link',{class:{
      'collection-item': true,
      'crd-background-gold': _vm.item.rarity === 'Gold',
      'crd-background-platinum': _vm.item.rarity === 'Platinum',
      'crd-background-silver': _vm.item.rarity === 'Silver',
      'crd-background-diamond': _vm.item.rarity === 'Diamond',
      'crd-background-commercial': _vm.item.rarity.includes('Commercial')
    },attrs:{"to":{name: 'SaleItemDetail', params: {id: _vm.item.map_id}}}},[_c('div',{staticClass:"collection-item__content"},[_c('div',{staticClass:"collection-item__img-wrp"},[_c('img',{staticClass:"collection-item__img",attrs:{"src":_vm.item.image}})]),_c('div',{staticClass:"collection-item__info"},[_c('div',{staticClass:"collection-item__tag"},[_c('span',{class:{
            'collection-item__tag-name':  true,
            'crd-tag-gold': _vm.item.rarity === 'Gold',
            'crd-tag-platinum': _vm.item.rarity === 'Platinum',
            'crd-tag-silver': _vm.item.rarity === 'Silver',
            'crd-tag-diamond': _vm.item.rarity === 'Diamond',
          }},[_vm._v(" "+_vm._s(_vm.item.rarity)+" ")])]),_c('h1',{staticClass:"collection-item__title"},[_vm._v(_vm._s(_vm.item.name))])]),_c('div',{class:{
        'collection-item__bottom': true,
        'crd-bottom-gold': _vm.item.rarity === 'Gold',
        'crd-bottom-platinum': _vm.item.rarity === 'Platinum',
        'crd-bottom-silver': _vm.item.rarity === 'Silver',
        'crd-bottom-diamond': _vm.item.rarity === 'Diamond',
        'crd-bottom-commercial': _vm.item.rarity.includes('Commercial'),
      }},[_c('span',{staticClass:"collection-item__bottom-amount"},[_vm._v(" "+_vm._s(_vm.formatNumber(Math.max(_vm.item.maximum_supply - _vm.item.current_supply, 0)) + '/' + _vm.formatNumber(_vm.item.maximum_supply))+" ")]),(Number(_vm.item.value) > 0)?_c('div',{staticClass:"collection-item__bottom-price"},[_c('img',{staticClass:"collection-item__bottom-price-img",attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/bnb-white.png"}}),_c('span',{staticClass:"collection-item__bottom-price-number"},[_vm._v(_vm._s(_vm.item.value)+" BNB")])]):_c('div',{staticClass:"collection-item__bottom-logo"},[_c('img',{staticClass:"collection-item__bottom-logo-img",attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/tcg_logo_circles.png"}}),_c('span',{staticClass:"collection-item__bottom-logo-label"},[_vm._v("TCG World")])])])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }