class Constants {
  static readonly OFFSET_X = 9620
  static readonly OFFSET_Y = 11012
  static readonly DRAWING_STEP = 1000
  static readonly BACKGROUND_ALPHA = 0.8
  static readonly COMMERCIAL_OUTLINES_AMOUNT = 34
  static readonly PLOTS_AMOUNT = 100435
  static readonly MAP_MARGIN = 4
  static readonly BLOCK_SIZE = 624
  static readonly LINE_WIDTH = 16
  static readonly ROAD_SIZE = 112
  static readonly COMMERCIAL_PLOT_SIZE = Constants.BLOCK_SIZE * 2
    + Constants.ROAD_SIZE + Constants.LINE_WIDTH;
  static readonly REGION_SIZE = Constants.BLOCK_SIZE * 14 + Constants.ROAD_SIZE * 7
  static readonly MAP_SIZE = Constants.REGION_SIZE * 2
    + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
  static readonly BG_MAP_WIDTH_SIZE = Constants.MAP_SIZE * 2.1
  static readonly BG_MAP_HEIGHT_SIZE = Constants.MAP_SIZE * 2
  static readonly MAP_INIT_POSITION_X = Constants.BG_MAP_WIDTH_SIZE / 2
    - Constants.MAP_SIZE / 2 + 600
  static readonly MAP_INIT_POSITION_Y = Constants.BG_MAP_HEIGHT_SIZE / 2
    - Constants.MAP_SIZE / 2 - 1200
  static readonly OWNED_COLOR = 0x32FF7E
  static readonly DISABLED_COLOR = 0x262626
}

export default Constants;
