/* eslint-disable object-property-newline */

// ----------- Commecrial Plots ----------- //
import cyanBuild from '@/assets/images/map/commercial-plots/cyan.png';
import firstBlue from '@/assets/images/map/commercial-plots/first-blue.png';
import secondBlue from '@/assets/images/map/commercial-plots/second-blue.png';
import green from '@/assets/images/map/commercial-plots/green.png';
import secondGreen from '@/assets/images/map/commercial-plots/second-green.png';
import pink from '@/assets/images/map/commercial-plots/pink.png';

// ----------- Logos ----------- //
import aussie from '@/assets/images/map/logos/aussie.png';
import defi from '@/assets/images/map/logos/defi.png';
import elongate from '@/assets/images/map/logos/elongate.png';
import nftb from '@/assets/images/map/logos/nftb.png';
import primaryMobile from '@/assets/images/map/logos/primary-mobile.png';
import altcoin from '@/assets/images/map/logos/altcoin.png';
import sportemon from '@/assets/images/map/logos/sportemon.png';
import starship from '@/assets/images/map/logos/starship.png';
import wic from '@/assets/images/map/logos/wic.png';
import wsb1 from '@/assets/images/map/logos/wsb-1.png';
import wsb2 from '@/assets/images/map/logos/wsb-2.png';
import nafter from '@/assets/images/map/logos/nafter.png';
import valorsports from '@/assets/images/map/logos/valorsportscoin.png';
import greedToken from '@/assets/images/map/logos/greed-token.png';
import projectNightfall from '@/assets/images/map/logos/project-nightfall.png';
import cryptoMom from '@/assets/images/map/logos/crypto-mom.png';
import sonyaMulkeet from '@/assets/images/map/logos/sonya-mulkeet.png';
import maticGreys from '@/assets/images/map/logos/matic-greys.png';
import stockDads from '@/assets/images/map/logos/stock-dads.png';
import untitled from '@/assets/images/map/logos/untitled.png';
import fmfh from '@/assets/images/map/logos/fmfh.png';
import sydtek from '@/assets/images/map/logos/sydtek.png';
import collectaball from '@/assets/images/map/logos/collectaball.png';
import cyberHornets from '@/assets/images/map/logos/cyber-hornets.png';
import shesBlockchainSavvy from '@/assets/images/map/logos/shes-blockchain-savvy.png';
import blockchainCollective from '@/assets/images/map/logos/blockchain-collective.png';
import ggToor from '@/assets/images/map/logos/gg-toor.png';
import ggToorWhite from '@/assets/images/map/logos/gg-toor-white.png';
import monsterJam from '@/assets/images/map/logos/monster-jam.png';
import epik from '@/assets/images/map/logos/epik.png';
import readyplayerme from '@/assets/images/map/logos/readyplayerme.png';
import onetreeplanted from '@/assets/images/map/logos/onetreeplanted.png';
import drcTransformed from '@/assets/images/map/logos/drc-transformed.png';

// ----------- Areas ----------- //
import mapBg from '@/assets/images/map/map_bg.png';
import ruinsArea from '@/assets/images/map/areas/ruins_area.png';
import vikingArea from '@/assets/images/map/areas/viking-village.png';
import beachArea from '@/assets/images/map/areas/beach_area.png';
import skiArea from '@/assets/images/map/areas/ski_area.png';
import festivalArea from '@/assets/images/map/areas/festival_area.png';
import volcanoArea from '@/assets/images/map/areas/volcano_area.png';
import golfArea from '@/assets/images/map/areas/golf_area.png';
import islandArea from '@/assets/images/map/areas/island_area.png';
import glacialRuinsArea from '@/assets/images/map/areas/glacial_ruins.png';

// ----------- Area Icons ----------- //
import ruins from '@/assets/images/map/areas/icons/ruins.png';
import vikings from '@/assets/images/map/areas/icons/viking.png';
import palm from '@/assets/images/map/areas/icons/palm.png';
import festival from '@/assets/images/map/areas/icons/festival.png';
import golf from '@/assets/images/map/areas/icons/golf.png';
import ski from '@/assets/images/map/areas/icons/ski.png';
import volcano from '@/assets/images/map/areas/icons/volcano.png';
import island from '@/assets/images/map/areas/icons/island.png';
import autovalley from '@/assets/images/map/areas/auto-valley.png';
import glacialRuins from '@/assets/images/map/areas/icons/glacial-ruins.png';

// ----------- Regions ----------- //
import asia from '@/assets/images/map/regions/asia.png';
import east from '@/assets/images/map/regions/east.png';
import forest from '@/assets/images/map/regions/forest.png';
import north from '@/assets/images/map/regions/north.png';
import center from '@/assets/images/map/regions/center.png';

// ----------- Constructions ----------- //
import plane from '@/assets/images/map/facilities/plane.png';
import cup from '@/assets/images/map/facilities/cup.png';
import flag from '@/assets/images/map/facilities/flag.png';
import rod from '@/assets/images/map/facilities/rod.png';
import anchor from '@/assets/images/map/facilities/anchor.png';
import roller from '@/assets/images/map/facilities/roller.png';
import gallery from '@/assets/images/map/facilities/gallery.png';
import yatch from '@/assets/images/map/facilities/yatch.png';
import ship from '@/assets/images/map/facilities/ship.png';
import rocket from '@/assets/images/map/facilities/rocket.png';
import adventureParkBackground from '@/assets/images/map/facilities/adventure-park-background.png';

import horse from '@/assets/images/map/areas/icons/horse.png';
import horsesArea from '@/assets/images/map/areas/horses-bg.png';

export default {
  // ----------- Commecrial Plots ----------- //
  cyanBuild, firstBlue, secondBlue,
  green, secondGreen, pink,
  // ----------- Logos ----------- //
  aussie, defi, elongate,
  nftb, primaryMobile, altcoin,
  sportemon, starship, wic,
  wsb1, wsb2, nafter,
  valorsports, greedToken, projectNightfall,
  cryptoMom, sonyaMulkeet, maticGreys,
  stockDads, untitled, fmfh,
  sydtek, collectaball, cyberHornets,
  shesBlockchainSavvy, blockchainCollective,
  ggToor, ggToorWhite, monsterJam,
  epik, onetreeplanted, readyplayerme,
  drcTransformed,
  // ----------- Areas ----------- //
  mapBg, ruinsArea, beachArea,
  skiArea, festivalArea, volcanoArea,
  golfArea, islandArea, glacialRuinsArea,
  vikingArea, horsesArea,
  // ----------- Area Icons ----------- //
  ruins, palm, festival,
  golf, ski, volcano,
  island, autovalley, glacialRuins,
  vikings, horse,
  // ----------- Regions ----------- //
  asia, east, forest,
  north, center,
  // ----------- Constructions ----------- //
  plane, cup, flag,
  rod, anchor, roller,
  gallery, yatch, ship,
  rocket, adventureParkBackground,
};
