import Constants from '@/variables/map/constants';
import assets from '@/variables/map/assets';

const regions = [
  {
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE / 2
      - Constants.COMMERCIAL_PLOT_SIZE / 2 + Constants.ROAD_SIZE,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE / 2
      - Constants.COMMERCIAL_PLOT_SIZE / 2 + Constants.ROAD_SIZE,
    img: assets.asia,
    id: 1,
  },
  {
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
      + Constants.REGION_SIZE / 2 - Constants.COMMERCIAL_PLOT_SIZE / 2,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE / 2
      - Constants.COMMERCIAL_PLOT_SIZE / 2 + Constants.ROAD_SIZE,
    img: assets.north,
    id: 2,
  },
  {
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE / 2
      - Constants.COMMERCIAL_PLOT_SIZE / 2 + Constants.ROAD_SIZE,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
      + Constants.REGION_SIZE / 2 - Constants.COMMERCIAL_PLOT_SIZE / 2,
    img: assets.forest,
    id: 3,
  },
  {
    x:
      Constants.MAP_INIT_POSITION_X + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
      + Constants.REGION_SIZE / 2 - Constants.COMMERCIAL_PLOT_SIZE / 2,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.REGION_SIZE
      + Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE * 2
      + Constants.REGION_SIZE / 2 - Constants.COMMERCIAL_PLOT_SIZE / 2,
    img: assets.east,
    id: 4,
  },
];

export default regions;
