import { Viewport } from 'pixi-viewport';
import {
  PlotData,
  PlotInit,
  PopupPosition,
  DisplayObjectWithPlotData,
} from '@/variables/map/types';

class Popup {
  getPopupPosition(
    this: Popup,
    target: DisplayObjectWithPlotData,
    viewport: Viewport,
  ): PopupPosition {
    const position = {
      top: 0,
      left: 0,
    };

    position.top = (target.y + (target.height as number)) * viewport.scaled + viewport.y;
    position.left = (target.x + (target.width as number)) * viewport.scaled + viewport.x;

    return position;
  }

  async getPlotData(this: Popup, plot: PlotInit): Promise<PlotData> {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_API_LOCATION}/api/v1/map/get_item_by_coordinates/?coord_x=${plot.x}&coord_y=${plot.y}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const data = await response.json();
      return data;
    } catch (err: unknown) {
      throw new Error((err as Error)?.message);
    }
  }
}

export default Popup;
