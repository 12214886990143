import Constants from '@/variables/map/constants';
import assets from '@/variables/map/assets';
import glacialRuinsArea from '@/assets/images/map/areas/glacial_ruins.png';

const areas = [
  {
    width: 2000,
    height: Constants.REGION_SIZE - Constants.BLOCK_SIZE * 9 + Constants.ROAD_SIZE * 2,
    x: Constants.MAP_INIT_POSITION_X + Constants.MAP_SIZE,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.BLOCK_SIZE * 5 - Constants.ROAD_SIZE * 2.5,
    content: {
      text: 'Viking \nVillage',
      textPosition: {
        x: 200,
        y: 2000,
      },
      fontSize: 450,
      icon: assets.vikings,
      iconWidth: 1300,
      iconHeight: 1100,
      iconPosition: {
        x: 300,
        y: 750,
      },
    },
    img: assets.vikingArea,
    id: 0,
  },
  {
    width: 3500,
    height: Constants.REGION_SIZE - Constants.BLOCK_SIZE * 3,
    x: Constants.MAP_INIT_POSITION_X + Constants.MAP_SIZE,
    y:
      Constants.MAP_INIT_POSITION_Y + Constants.MAP_SIZE
      - Constants.REGION_SIZE + Constants.BLOCK_SIZE * 3,
    content: {
      text: 'Ancient \nRuins',
      textPosition: {
        x: 500,
        y: 3000,
      },
      fontSize: 500,
      icon: assets.ruins,
      iconWidth: 1300,
      iconHeight: 1100,
      iconPosition: {
        x: 800,
        y: 1800,
      },
    },
    img: assets.ruinsArea,
    id: 1,
  },
  {
    width: 19400,
    height: 19400,
    x: Constants.MAP_INIT_POSITION_X - 11850,
    y: Constants.MAP_INIT_POSITION_Y + Constants.MAP_SIZE - 14900,
    content: {
      text: 'Beach',
      textPosition: {
        x: 14000,
        y: 16500,
      },
      fontSize: 900,
      icon: assets.palm,
      iconWidth: 1600,
      iconHeight: 2000,
      iconPosition: {
        x: 12500,
        y: 16000,
      },
    },
    img: assets.beachArea,
    id: 2,
  },
  {
    width: 8000,
    height: 9000,
    x: Constants.MAP_INIT_POSITION_X + Constants.MAP_SIZE - 2000,
    y: Constants.MAP_INIT_POSITION_Y - 6000,
    content: {
      text: 'Ski Resort',
      textPosition: {
        x: 4300,
        y: 4000,
      },
      fontSize: 600,
      icon: assets.ski,
      iconWidth: 1600,
      iconHeight: 2000,
      iconPosition: {
        x: 4800,
        y: 2000,
      },
    },
    img: assets.skiArea,
    id: 3,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE,
    height: Constants.BLOCK_SIZE * 6 + Constants.BLOCK_SIZE / 2 + Constants.ROAD_SIZE * 3,
    x: Constants.MAP_INIT_POSITION_X - Constants.BLOCK_SIZE * 2,
    y: Constants.MAP_INIT_POSITION_Y + Constants.ROAD_SIZE,
    content: {
      text: 'Festival',
      textPosition: {
        x: 150,
        y: 2300,
      },
      fontSize: 300,
      icon: assets.festival,
      iconWidth: 600,
      iconHeight: 700,
      iconPosition: {
        x: 430,
        y: 1400,
      },
    },
    img: assets.festivalArea,
    id: 4,
  },
  {
    width: 2000,
    height: 1500,
    x: Constants.MAP_INIT_POSITION_X + 900,
    y: Constants.MAP_INIT_POSITION_Y - 4200,
    content: {
      text: 'Volcano',
      textPosition: {
        x: 450,
        y: 800,
      },
      fontSize: 300,
      icon: assets.volcano,
      iconWidth: 600,
      iconHeight: 700,
      iconPosition: {
        x: 700,
        y: 100,
      },
    },
    img: assets.volcanoArea,
    id: 5,
  },
  {
    width: 4500,
    height: 2000,
    x: Constants.MAP_INIT_POSITION_X + Constants.MAP_SIZE - Constants.BLOCK_SIZE * 2,
    y: Constants.MAP_INIT_POSITION_Y + Constants.MAP_SIZE + 200,
    content: {
      text: 'Golf',
      textPosition: {
        x: 1500,
        y: 1000,
      },
      fontSize: 700,
      icon: assets.golf,
      iconWidth: 700,
      iconHeight: 800,
      iconPosition: {
        x: 1850,
        y: 200,
      },
    },
    img: assets.golfArea,
    id: 6,
  },
  {
    width: 2000,
    height: 1200,
    x: Constants.MAP_INIT_POSITION_X + 5000,
    y: Constants.BG_MAP_HEIGHT_SIZE - 4000,
    content: {
      text: 'Island',
      textPosition: {
        x: 800,
        y: 400,
      },
      fontSize: 300,
      icon: assets.island,
      iconWidth: 500,
      iconHeight: 400,
      iconPosition: {
        x: 200,
        y: 400,
      },
    },
    img: assets.islandArea,
    id: 7,
  },
  {
    width: 2000,
    height: 1200,
    x: Constants.MAP_INIT_POSITION_X + 12200,
    y: Constants.BG_MAP_HEIGHT_SIZE - 3500,
    content: {
      text: 'Island',
      textPosition: {
        x: 800,
        y: 400,
      },
      fontSize: 300,
      icon: assets.island,
      iconWidth: 500,
      iconHeight: 400,
      iconPosition: {
        x: 200,
        y: 400,
      },
    },
    img: assets.islandArea,
    id: 8,
  },
  {
    width: 2000,
    height: 1200,
    x: Constants.MAP_INIT_POSITION_X + 9000,
    y: Constants.BG_MAP_HEIGHT_SIZE - 6000,
    content: {
      text: 'Island',
      textPosition: {
        x: 800,
        y: 400,
      },
      fontSize: 300,
      icon: assets.island,
      iconWidth: 500,
      iconHeight: 400,
      iconPosition: {
        x: 200,
        y: 400,
      },
    },
    img: assets.islandArea,
    id: 9,
  },
  {
    width: 2000,
    height: 1200,
    x: Constants.MAP_INIT_POSITION_X - 7000,
    y: Constants.BG_MAP_HEIGHT_SIZE - 6000,
    content: {
      text: 'Island',
      textPosition: {
        x: 800,
        y: 400,
      },
      fontSize: 300,
      icon: assets.island,
      iconWidth: 500,
      iconHeight: 400,
      iconPosition: {
        x: 200,
        y: 400,
      },
    },
    img: assets.islandArea,
    id: 10,
  },
  {
    width: 2000,
    height: 1200,
    x: Constants.MAP_INIT_POSITION_X - 4500,
    y: Constants.BG_MAP_HEIGHT_SIZE - 2700,
    content: {
      text: 'Island',
      textPosition: {
        x: 800,
        y: 400,
      },
      fontSize: 300,
      icon: assets.island,
      iconWidth: 500,
      iconHeight: 400,
      iconPosition: {
        x: 200,
        y: 400,
      },
    },
    img: assets.islandArea,
    id: 11,
  },
  {
    width: 2000,
    height: 1200,
    x: 700,
    y: Constants.BG_MAP_HEIGHT_SIZE - 16300,
    content: {
      text: 'Island',
      textPosition: {
        x: 800,
        y: 400,
      },
      fontSize: 300,
      icon: assets.island,
      iconWidth: 500,
      iconHeight: 400,
      iconPosition: {
        x: 200,
        y: 400,
      },
    },
    img: assets.islandArea,
    id: 12,
  },
  {
    width: Constants.BLOCK_SIZE * 6 + Constants.ROAD_SIZE * 3,
    height: 1500,
    x: Constants.MAP_INIT_POSITION_X
      + Constants.REGION_SIZE + Constants.BLOCK_SIZE * 6
      + Constants.ROAD_SIZE * 4,
    y: Constants.MAP_INIT_POSITION_Y - Constants.BLOCK_SIZE * 2 - Constants.ROAD_SIZE - 32,
    content: {
      text: 'Glacial Ruins',
      textPosition: {
        x: 1100,
        y: 900,
      },
      fontSize: 300,
      icon: assets.glacialRuins,
      iconWidth: 900,
      iconHeight: 800,
      color: '0x000000',
      iconPosition: {
        x: 1500,
        y: 200,
      },
    },
    img: assets.glacialRuinsArea,
    id: 13,
  },
  {
    width: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE,
    height: Constants.BLOCK_SIZE * 2 + Constants.ROAD_SIZE,
    x: Constants.MAP_INIT_POSITION_X
      + Constants.REGION_SIZE + Constants.BLOCK_SIZE * 8
      + Constants.ROAD_SIZE * 5,
    y: Constants.MAP_INIT_POSITION_Y - Constants.ROAD_SIZE * 4 + Constants.REGION_SIZE * 2 + Constants.BLOCK_SIZE * 3 + 56,
    content: {
      text: 'Mythic\nMeadows',
      textPosition: {
        x: 250,
        y: 725,
      },
      fontSize: 200,
      icon: assets.horse,
      iconWidth: 700,
      iconHeight: 700,
      color: '0xffffff',
      iconPosition: {
        x: 350,
        y: 60,
      },
    },
    img: assets.horsesArea,
    id: 14,
  },
];

export default areas;
